import React, { useState, useEffect } from "react";

const backendUrl = "https://portal.lsb.go.ke/account/licensed/";

function CurrentRegister() {
  const [surveyors, setSurveyors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(20); // Number of items to fetch per page

  // Fetch data from the backend with limit and offset
  const fetchData = async (page) => {
    setLoading(true);
    const offset = (page - 1) * itemsPerPage; // Calculate offset based on the current page
    try {
      const response = await fetch(`${backendUrl}?limit=${itemsPerPage}&offset=${offset}`);
      const data = await response.json();
      setSurveyors(data.results);
      setTotalPages(Math.ceil(data.count / itemsPerPage)); // Update total pages based on the total count
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
      setLoading(false);
    }
  };

  // Fetch data when component mounts or when the currentPage changes
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  // Update search results when search term changes
  useEffect(() => {
    if (searchTerm) {
      const results = surveyors.filter((surveyor) =>
        surveyor.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults(surveyors);
    }
  }, [searchTerm, surveyors]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Filter the data based on the current page and search term
  const getCurrentPageData = () => {
    if (searchTerm) {
      return searchResults.slice(0, itemsPerPage); // Show search results, first 20 items only
    }
    return surveyors;
  };

  const CurrentYear = () => {
    const year = new Date().getFullYear();
    return <span>{year}</span>;
  };

  const renderTableHeaders = () => (
    <thead className="uppercase">
      <tr>
        <th scope="col" className="py-2 px-3 bg-black text-white">
          SNo.
        </th>
        <th scope="col" className="bg-black text-white">
          NAME
        </th>
        <th scope="col" className="bg-black text-white">
          LICENCE
        </th>
        <th scope="col" className="px-3 bg-black text-white">
          ORGANIZATION
        </th>
        <th scope="col" className="bg-black text-white">
          ADDRESS
        </th>
        <th scope="col" className="bg-black text-white">
          <CurrentYear /> {" "} STATUS
        </th>
      </tr>
    </thead>
  );

  const renderTableRows = (data) => (
    <tbody>
      {data.map((surveyor, index) => (
        <tr
          key={index}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td className="py-2 px-3">{surveyor.licensed_id}</td>
          <td>{surveyor.name}</td>
          <td>{surveyor.license_no}</td>
          <td className="px-3">{surveyor.firm}</td>
          <td>{surveyor.postal_address}</td>
          <td>{surveyor.status}</td>
        </tr>
      ))}
    </tbody>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="relative overflow-x-auto">
        <label
          htmlFor="licensedSurveyor"
          className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600"
        >
          <input
            type="text"
            placeholder=""
            className="peer h-12 w-full border-none bg-gray-200 px-4 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 rounded-t-lg fw-bold"
            value={searchTerm}
            onChange={handleSearchChange}
          />

          <span
            className="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-800 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs px-4"
          >
            Search by name...
          </span>
        </label>
      </div>

      <div className="overflow-x-auto mt-2">
        <table className="w-full text-sm text-left rtl:text-right table table-striped">
          {renderTableHeaders()}
          {renderTableRows(getCurrentPageData())}
        </table>
      </div>

      <div className="flex justify-between mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 disabled:opacity-50"
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default CurrentRegister;