import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Portal() {
  const [id_number, setIdNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!id_number || !password) {
      setError('Both fields are required');
      return;
    }

    try {
      const response = await fetch('https://portal.lsb.go.ke/api/token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id_number, password }),
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        // Store the access token in localStorage
        localStorage.setItem('accessToken', data.accessToken);
        // Store the refresh token in localStorage
        localStorage.setItem('refreshToken', data.refreshToken);
        // Store user status in localStorage
        localStorage.setItem('userStatus', JSON.stringify(data.status));
        // Redirect to the dashboard
        navigate('/dashboard');
      } else {
        const data = await response.json();
        setError(data.message || 'Login failed');
      }
    } catch (err) {
      setError('Error during login: ' + err.message);
    }
  };

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      // Function to refresh the access token
      const refreshAccessToken = async () => {
        try {
          const response = await fetch('https://portal.lsb.go.ke/api/token/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Referer': 'https://lsb.go.ke/portal'
            },
            body: JSON.stringify({ refreshToken }),
            credentials: 'include'
          });

          if (response.ok) {
            const data = await response.json();
            localStorage.setItem('accessToken', data.accessToken);
            // Update user status if it's included in the refresh response
            if (data.status) {
              localStorage.setItem('userStatus', JSON.stringify(data.status));
            }
          } else {
            // If refresh fails, clear tokens and redirect to login
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userStatus');
            navigate('/portal');
          }
        } catch (err) {
          console.error('Error refreshing token:', err);
        }
      };

      // Refresh the token when the component mounts
      refreshAccessToken();

      // Set up an interval to refresh the token periodically
      const refreshInterval = setInterval(refreshAccessToken, 15 * 60 * 1000); // 15 minutes

      return () => clearInterval(refreshInterval);
    }
  }, [navigate]);

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-lg">
        <h1 className="text-center font-bold">Surveyor's Portal</h1>

        <form
          onSubmit={handleSubmit}
          className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
        >
          {error && <p className="text-red-500">{error}</p>}

          <div>
            <label htmlFor="id_number" className="sr-only">ID Number</label>
            <div className="relative">
              <input
                type="number"
                name="id_number"
                value={id_number}
                onChange={(e) => setIdNumber(e.target.value)}
                className="w-full rounded-lg border-gray-200 p-4 pe-12 shadow-sm"
                placeholder="ID Number"
                required
              />
              <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                <i className="fa-solid fa-envelope"></i>
              </span>
            </div>
          </div>

          <div>
            <label htmlFor="password" className="sr-only">Password</label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                placeholder="Password"
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 end-0 grid place-content-center px-4 cursor-pointer"
              >
                {showPassword ? (
                  <i className="fa-regular fa-eye"></i>
                ) : (
                  <i className="fa-regular fa-eye-slash"></i>
                )}
              </button>
            </div>
          </div>

          <button type="submit" className="button">
            <span className="button_lg">
              <span className="button_sl"></span>
              <span className="button_text">Sign In</span>
            </span>
          </button>
        </form>
      </div>
    </div>
  );
}

export default Portal;