import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';

import './App.css';

//components
import Navbar from "./components/Navbar/navigation";
import Footer from './components/Footer/Footer';
import NavItems from "./components/NavItems/NavItems";
import Home from "./components/home/Home";
import About from "./components/About/About";
import Register from "./components/Register/Register";
import Resources from "./components/Resources/resources";
import Portal from './components/Portal/Portal';
import Contact from './components/Contact/Contact';
import Dashboard from './components/Dashboard/Dashboard';

// feedback
import Compliments from './components/compliments/compliments';
import Inquiries from './components/inquiries/inquiries';
import Complaints from "./components/Complaints/complaints";
import Report from './components/Report/Report';
// feedback

// services
import Registration from './components/Services/Registration';
import SurveyCourse from './components/Services/SurveyCourse';
import SurveyorReg from './components/Services/SurveyorReg';
import AnnualLicence from './components/Services/AnnualLicence';
import ApprovedReg from './components/Services/ApprovedReg';
import IDCards from './components/Services/IDCards';
import EventsReg from './components/Services/EventsReg';
import LandLaw from './components/Services/LandLaw';
import TitleSurv from './components/TitleSurv/TitleSurv';
import EngageSurv from './components/EngageSurv/EngageSurv';
import TrialSurvey from './components/TrialSurvey/TrialSurvey';
import NotFound from './components/NotFound';

// import ContinuingDev from './components/Services/ContinuingDev';
// services

// profiles
import PublicProfile from './components/Register/Profiles/PublicProfile';

// footer
import PrivacyPolicy from './components/Footer/PrivacyPolicy';

// add the other components

function App() {
  return (
    <div className="App">
      <Navbar />
      <NavItems/>

      {/* add the additional routes as needed */}
      <Routes>
        <Route path="/" exact element={<Home />} />;
        <Route path="/about" element={<About />} />;
        <Route path="/register" element={<Register />} />;
        
        {/* feedback */}
        <Route path="/compliments" element={<Compliments/>}/>;
        <Route path="/inquiries" element={<Inquiries/>}/>;
        <Route path="/complaints" element={<Complaints/>}/>;
        <Route path="/resources" element={<Resources/>}/>;
        <Route path="/report" element={<Report/>}/>;

        {/* services */}
        <Route path="/registration" element={<Registration/>}/>;
        <Route path="/surveyorreg" element={<SurveyorReg/>}/>;
        <Route path="/annuallicence" element={<AnnualLicence/>}/>;
        <Route path="/approvedReg" element={<ApprovedReg/>}/>;
        <Route path="/idcards" element={<IDCards/>}/>;
        <Route path="/eventsReg" element={<EventsReg/>}/>;
        <Route path="/landLaw" element={<LandLaw/>}/>;
        <Route path="/surveyCourse" element={<SurveyCourse/>}/>;
        <Route path="/trialSurvey" element={<TrialSurvey/>}/>;
        {/* <Route path="/continuingDev" element={<ContinuingDev/>}/>; */}
        {/* services */}

        <Route path="/titleSurv" element={<TitleSurv/>}/>;
        <Route path="/engageSurv" element={<EngageSurv/>}/>;
        <Route path="/portal" element={<Portal/>}/>;
        <Route path="/contact" element={<Contact/>}/>;
        <Route path="/privacy" element={<PrivacyPolicy/>}/>;

        {/* profiles */}
        <Route path="/publicProfile/:licenseNumber" element={<PublicProfile/>}/>;
        <Route path="/dashboard" element={<Dashboard/>}/>;
        <Route path="*" element={<NotFound/>}/>
      </Routes>

      <Footer/>
    </div>
  );
}

export default App;
