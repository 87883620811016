import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [uploadError, setUploadError] = useState("");
  const navigate = useNavigate();

  // Function to handle file selection
  const handleFileSelect = (e) => {
    const files = e.target.files;
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    const selectedFiles = Array.from(files);
    const invalidFiles = selectedFiles.filter(file => !allowedTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      setUploadError('Invalid file type. Please upload a DOCX, PNG, JPG, or PDF file.');
      e.target.value = ''; // Clear the file input
    } else {
      setUploadedDocuments([...uploadedDocuments, ...selectedFiles]);
      setUploadError('');
    }
  };

  // Function to remove uploaded file
  const removeFile = (index) => {
    const updatedFiles = [...uploadedDocuments];
    updatedFiles.splice(index, 1);
    setUploadedDocuments(updatedFiles);
  };

  // Function to handle logout
  const handleLogout = () => {
    // Clear all authentication tokens from localStorage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userStatus');

    // Redirect to the login page
    navigate('/portal');
  };

  return (
    <div className="container mx-auto px-4 py-6 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold sm:text-3xl">Hello, [username]</h1>
          <p className="text-sm text-gray-600">_</p>
        </div>
        <button
          onClick={handleLogout}
          className="bg-red-500 hover:bg-red-700 text-white hover:font-bold py-2 px-4"
        >
          Logout
        </button>
      </div>

      <form className="space-y-6">
        <p className="font-bold underline underline-offset-2 text-red-500">Update your Details</p>

        <div className="flex flex-col sm:flex-row gap-4 items-center border-b pb-4">
          <p className="w-full sm:w-auto">Change Password *</p>
          <input placeholder="New Password" type="password" className="w-full sm:w-auto rounded-md px-3 py-2 border border-gray-300" />
        </div>

        <div className="border-b pb-6">
          <p className="font-bold mb-4">Personal Details</p>

          <div className="mb-4">
            <div className="mt-2">
              <div className="flex items-center justify-center w-full">
                <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <i className="fa-solid fa-cloud-arrow-up fa-3x mb-3"></i>
                    <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Upload</span> Profile Photo</p>
                    <p className="text-xs text-gray-500">Allowable formats: PNG, JPG</p>
                    <small className="text-gray-500">Ensure any image uploaded is clear</small>
                  </div>
                  <input id="dropzone-file" type="file" className="hidden" accept=".docx,.png,.jpg,.pdf" onChange={handleFileSelect} multiple />
                </label>
              </div>
            </div>

            {uploadError && (
              <p className="text-red-500 text-sm mt-2">{uploadError}</p>
            )}
            {uploadedDocuments.length > 0 && (
              <ul className="mt-2 space-y-1">
                <small className="underline font-bold">Uploaded Documents:</small>
                {uploadedDocuments.map((file, index) => (
                  <li key={index} className="flex items-center justify-between">
                    <span className="truncate">{file.name}</span>
                    <button onClick={() => removeFile(index)} className="ml-2 text-red-600 font-bold">Remove</button>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <input type="text" id="firstName" placeholder="First Name" className="w-full rounded-md px-3 py-2 border border-gray-300" />
            <input type="text" id="lastName" placeholder="Last Name" className="w-full rounded-md px-3 py-2 border border-gray-300" />
            <input type="text" id="idNumber" placeholder="ID Number" className="w-full rounded-md px-3 py-2 border border-gray-300" />
          </div>
        </div>

        <div className="border-b pb-6">
          <p className="font-bold mb-4">Location/ Address</p>
          <input placeholder="County" className="w-full rounded-md px-3 py-2 border border-gray-300" />
        </div>

        <div className="border-b pb-6">
          <p className="font-bold mb-4">Assistants</p>
          <div className="overflow-x-auto">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-200 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">#</th>
                  <th className="py-3 px-6 text-left">First Name</th>
                  <th className="py-3 px-6 text-left">Last Name</th>
                  <th className="py-3 px-6 text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                <tr className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left whitespace-nowrap">1.</td>
                  <td className="py-3 px-6 text-left">John</td>
                  <td className="py-3 px-6 text-left">Doe</td>
                  <td className="py-3 px-6 text-center">
                    <i className="fa-regular fa-pen-to-square text-green-500 hover:text-green-700 cursor-pointer"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <button className="w-full sm:w-auto mt-4 py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">
          Submit
        </button>
      </form>
    </div>
  )
}

export default Dashboard;