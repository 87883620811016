import React, { useState, useEffect } from "react";

const backendUrl = "https://portal.lsb.go.ke/account/approved/";

function CurrentRegister() {
  const [assistants, setAssistants] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAssistants, setFilteredAssistants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(20); // Number of items per page

  // Fetch data from the backend with pagination (limit and offset)
  const fetchData = async (page) => {
    setLoading(true);
    const offset = (page - 1) * itemsPerPage;
    try {
      const response = await fetch(`${backendUrl}?limit=${itemsPerPage}&offset=${offset}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setAssistants(data.results);
      setFilteredAssistants(data.results); // Default to full list until search is applied
      setTotalPages(Math.ceil(data.count / itemsPerPage)); // Set total pages based on count
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // Fetch data when component mounts or when currentPage changes
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  // Update filtered results when search query or assistants data changes
  useEffect(() => {
    const filtered = assistants.filter((assistant) =>
      assistant.assistant_id?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredAssistants(filtered);
  }, [searchQuery, assistants]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="w-full">
      <div className="relative overflow-x-auto">
        <label
          htmlFor="approvedAssistants"
          className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600"
        >
          <input
            type="text"
            placeholder=""
            className="peer h-12 w-full border-none bg-gray-200 px-4 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 rounded-t-lg fw-bold"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <span className="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-800 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs px-4">
            Search by Assistant ID
          </span>
        </label>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <table className="w-full text-sm text-left rtl:text-right table table-striped mt-2">
          <thead>
            <tr className="bg-white border-b">
              <th className="py-1 px-3 bg-black text-white">APPROVED ASST.</th>
              <th className="py-1 px-3 bg-black text-white">ASSISTANT No.</th>
              <th className="py-1 bg-black text-white">SUPERVISED BY</th>
              <th className="py-1 bg-black text-white">SUPERVISOR LICENSE</th>
            </tr>
          </thead>
          <tbody>
            {filteredAssistants.length > 0 ? (
              filteredAssistants.map((row, index) => (
                <tr key={index}>
                  <td>{row.assistant_name}</td>
                  <td>{row.assistant_no}</td>
                  <td>{row.supervisor_name}</td>
                  <td>{row.supervisor_license}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No assistants found.</td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <div className="flex justify-between mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-blue-500 text-white px-4 py-2 disabled:bg-gray-300"
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-blue-500 text-white px-4 py-2 disabled:bg-gray-300"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default CurrentRegister;